import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  private baseUrl: string;
  baseUrlApi: string;

  constructor() {
    // this.baseUrl = 'https://mfcsa2.herokuapp.com/';
    // this.baseUrlApi = 'https://mfcsa2.herokuapp.com/api/';
    this.baseUrl = environment.baseUrl;
    this.baseUrlApi = environment.baseUrlApi;
  }
  public getBaseUrl() {
    return this.baseUrl;
  }
  public getBaseUrlApi() {
    return this.baseUrlApi;
  }
}
